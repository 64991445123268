<script setup>
import { computed, ref } from 'vue'
import { Head, Link, useForm, usePage } from '@inertiajs/vue3'
// import { useRecaptcha } from '@/Hooks/recaptcha.js'
import Webpass from '@laragear/webpass'

import AuthenticationCard
  from '@/Components/Authentication/AuthenticationCard.vue'
import AuthenticationCardLogo
  from '@/Components/Authentication/AuthenticationCardLogo.vue'
import AuthRecaptchaNotice
  from '@/Components/Authentication/AuthRecaptchaNotice.vue'
import Checkbox from '@/Components/Form/Checkbox.vue'
import InputError from '@/Components/Form/InputError.vue'
import InputLabel from '@/Components/Form/InputLabel.vue'
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue'
import TextInput from '@/Components/Form/TextInput.vue'
import Image from '@/Components/Image.vue'

defineProps({
  canResetPassword: Boolean,
  status: String,
})

const form = useForm({
  email: '',
  password: '',
  captcha_token: "",
  remember: false,
})

// const { getToken: getRecaptchaToken } = useRecaptcha('login')

const passkeyLoggingIn = ref(false)

const loggingIn = computed(() => passkeyLoggingIn.value === true
  || form.processing)

const submit = async () => {
  const formData =   { remember: form.remember ? 'on' : '' }

  // if (recaptchaEnabled in window && recaptchaEnabled) {
  //   formData.captcha_token = await getRecaptchaToken()
  // }

  form.transform(data => ({
    ...data,
    ...formData,
  }))
  .post(route('login'), {
    onFinish: () => form.reset('password'),
  })
}

const hasRecaptcha = recaptchaEnabled in window && recaptchaEnabled

const passkeyLogin = async () => {
  passkeyLoggingIn.value = true

  const body = { remember: form.remember ? 'on' : '' }

  // if (hasRecaptcha) {
  //   body.captcha_token = await getRecaptchaToken()
  // }

  const response = await Webpass.assert(
    {
      path: route('webauthn.login.options'),
      headers: { 'X-Auth-Type': 'WebAuthn' },
      credentials: 'include',
    },
    {
      path: route('webauthn.login'),
      headers: { 'X-Auth-Type': 'WebAuthn' },
      credentials: 'include',
      body,
    },
  )

  passkeyLoggingIn.value = true

  const status = response?.data?.status || ''
  const error = response?.data?.error || ''
  const redirect = response?.data?.redirect || ''

  if (status === 'error' && error === 'recaptcha') {
    alert('Sorry, you did not pass reCaptcha.')
  } else if ((redirect?.length||0) > 0) {
    location.replace(redirect)
  } else {
    console.log({ status, error, redirect })

    alert('Sorry, something wrong happened in the server.')
  }
}

const hasWebAuthn = usePage().props.jetstream.hasWebauthn
</script>

<template>
  <Head title="Log in" />

  <AuthenticationCard>
    <template #logo>
      <AuthenticationCardLogo />
    </template>

    <template #intro>
      <h2 class="font-extrabold text-3xl text-center">
        Sign in to your account
      </h2>

      <p class="mt-2 text-sm text-center">
        Or
        <Link
          class="font-medium text-secondary underline"
          :href="route('register')"
        >open an account</Link>
      </p>
    </template>

    <div
      v-if="status"
      class="m6-4 mb-4 font-medium text-sm text-green-600"
    >
      {{ status }}
    </div>

    <form class="space-y-6 mt-8" @submit.prevent="submit">
      <div>
        <InputLabel for="email" value="Email address" />

        <div class="mt-1">
          <TextInput
            id="email"
            v-model="form.email"
            type="email"
            required
            autofocus
            autocomplete="username"
          />
          <InputError class="mt-2" :message="form.errors.email" />
        </div>
      </div>

      <div class="space-y-1">
        <InputLabel for="password" value="Password" />

        <div class="mt-1">
          <TextInput
            id="password"
            v-model="form.password"
            type="password"
            required
            autocomplete="current-password"
          />
          <InputError class="mt-2" :message="form.errors.password" />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <Checkbox v-model:checked="form.remember" id="remember" name="remember" />

          <InputLabel class="ms-2 text-sm" for="remember" value="Remember me" />
        </div>

        <div class="text-sm">
          <Link
            v-if="canResetPassword"
            :href="route('password.request')"
            class="font-medium text-secondary underline"
          >
            Forgot your password?
          </Link>
        </div>
      </div>

      <div>
        <div
          class="grid grid-cols-7 gap-2 w-full"
        >
          <PrimaryButton
            :class="{
              'opacity-25': loggingIn,
              'col-span-6': hasWebAuthn,
              'col-span-7': !hasWebAuthn,
            }"
            :disabled="loggingIn"
            v-tooltip="hasWebAuthn ? 'Sign-in using email & password' : false"
            class="transition-all"
          >
            Sign in
          </PrimaryButton>

          <button
            v-if="hasWebAuthn"
            :class="{ 'opacity-25': loggingIn }"
            :disabled="loggingIn"
            v-tooltip="'Sign-in with your device using passkey'"
            @click.prevent="passkeyLogin"
            class="col-span-1 inline-flex items-center px-2 py-1 bg-white border
              border-gray-300 rounded-md font-semibold text-xs text-gray-700
              tracking-widest shadow-sm hover:bg-gray-50 focus:outline-none
              disabled:opacity-25 ease-in-out duration-150 transition-all"
            type="button"
          >
            <Image
              class="w-6 h-6 mx-auto"
              src="images/touchid.png"
              alt="Passkey icon"
            />
          </button>
        </div>
      </div>

      <AuthRecaptchaNotice />
    </form>
  </AuthenticationCard>
</template>
